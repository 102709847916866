import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InformacionVideo from './informacionVideo';
import InformacionImg from './informacionImg';
import Carta from './carta';
import { Container, Grid } from '@mui/material';
import Repuesto from './repuestos';
import Mp from './mp';
import {getProductosImg} from '../consumos/img'
const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default function GoogleTabs({ linea,producto, ProductoDescripcion , Valor, Moneda, Impuesto, Cantidad, dtl, iva, descuento}) {
  const getShopify = (dato) => {
    return new Promise((resolve) => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "id": "" + dato + ""
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch("https://aplicaciones-opcjfvvdrq-uc.a.run.app/graphqlData", requestOptions)
        .then(response => response.text())
        .then(result => {
          console.log("jeison"+result)
          setValueShopi(result)
          resolve(result)
        })
        .catch(error => console.log('error', error));
    })
  }
  //cambio
  const getRepuestos = (dato) => {
    return new Promise((resolve) => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        "query": "SELECT (select  concat( cast(KCAMOUNTUATION5766473FFF195FF7 as float64),' ' ,CCURRENUATIONFBAF0000D830F1E1)   from `citalsa-1573565243527.Prueba.ListaDePreciosMaterialesVista` where cast(t1.SKU as string )= CPRICESUATION503363A1609841F3 and CPRICESUATION503363A1609841F3 != '' ) as valor ,* FROM  `citalsa-1573565243527.Prueba.RepuestosSugeridos` t1 where t1.SKUEQUIPO = '"+dato+"'"
      });
      console.log(raw)
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch("https://aplicaciones-opcjfvvdrq-uc.a.run.app/bigquery", requestOptions)
        .then(response => response.text())
        .then(result => {
          setValueRepuestos(result)
          resolve(result)
        })
        .catch(error => console.log('error', error));
    })
  }


  const getMp = (dato) => {
    return new Promise((resolve) => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        "query": "SELECT * FROM  `citalsa-1573565243527.Prueba.RepuestosMP` where SKUEQUIPO = '"+dato+"'"
      });
      console.log(raw)
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch("https://aplicaciones-opcjfvvdrq-uc.a.run.app/bigquery", requestOptions)
        .then(response => response.text())
        .then(result => {
          setValueMp(result)
          resolve(result)
        })
        .catch(error => console.log('error', error));
    })
  }

  const [valueShopi, setValueShopi] = React.useState(null);
  const [valueRepuestos, setValueRepuestos] = React.useState(null);
  const [valueMp, setValueMp] = React.useState(null);
  const [video, setVideo] = React.useState(false);
  const [mp, setMp] = React.useState(false);
  const [repuesto, setRepuesto] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [productot, setProductot] = React.useState(producto);
  const [imgdos, setimgdos] = React.useState(producto);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (producto) {
      getShopify(producto).then((validar)=>{
        if(JSON.parse(validar).data?.products.edges.length !== 0){
           setVideo(true);
        }
      });
      getProductosImg(producto).then((item)=>{
        setimgdos(item)
      })
    }
  }, [producto]);
    {/*getRepuestos(producto).then((validar)=>{
        if(JSON.parse(validar).length !== 0){
          setRepuesto(true);
       }
      })
      getMp(producto).then((validar)=>{
        if(JSON.parse(validar).length !== 0){
          setMp(true);
       }
      }) */}
  return (
    <Container sx={{ padding: 0 }}>
      <Grid container>
        <Grid item xs={12}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Información general" />
            {ProductoDescripcion.includes("SERVICIO") ? null : <Tab label="Ficha técnica" />}
            {/*{repuesto?<Tab label="Kit sugerido de repuestos" />:""}*/}
            {/*{mp?<Tab label=" mantenimiento preventivo" />:""}*/}
            {/* Add more Tab components as needed */}
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <TabPanel value={value} index={0}>
            <Carta linea={linea} producto={producto} ProductoDescripcion={ProductoDescripcion} dtl={dtl} descuento={descuento} iva={iva} Valor={Valor} Moneda={Moneda} Impuesto={Impuesto} Cantidad={Cantidad}
               valueShopi={valueShopi  ? JSON.parse(valueShopi) : {}}  img2={imgdos}/>
           {video ?
                     <InformacionVideo valueShopi={valueShopi ? JSON.parse(valueShopi) : {}} /> : "" }
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="box">
              <InformacionImg producto={producto} ProductoDescripcion={ProductoDescripcion} valueShopi={valueShopi ? JSON.parse(valueShopi) : {}}  />
            </div>
          </TabPanel>

          <TabPanel value={value} index={2}>
            <div className="box">
                <Repuesto  repuestos={valueRepuestos ? JSON.parse(valueRepuestos) : {}} ></Repuesto> 
            </div>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <div className="box">
                <Mp  mp={valueMp ? JSON.parse(valueMp) : {}} ></Mp>
            </div>
          </TabPanel>

          {/* Add more TabPanel components corresponding to the Tabs */}
        </Grid>
      </Grid>
    </Container>
  );
}
