import * as React from 'react';
import { Card, CardContent,  Typography } from '@mui/material';
import { getProductos} from '../consumos/productosficha'

export default function InformacionImg({valueShopi, producto,ProductoDescripcion}) {
    const [url, setUrl] = React.useState('https://www.citalsa.com/cdn/shop/files/$producto_-_$descripcion.pdf`');
    
    
      React.useEffect(() => {
        if (valueShopi && valueShopi.data && valueShopi.data.products && valueShopi.data.products.edges && valueShopi.data.products.edges.length > 0) {
          const title = valueShopi.data.products.edges[0].node.title.replace(/ /g, "_");
          var productoactivo = producto;
           getProductos(producto).then((result)=>{
            productoactivo = result;
            const nuevaUrl = `https://www.citalsa.com/cdn/shop/files/${productoactivo}.pdf`;
            setUrl(nuevaUrl);
          })
          console.log(url)

        }
      }, [valueShopi]);

            
    return (
        <Card sx={{ width:"100%", height:"1200px" }}>
            <CardContent >
                <iframe style={{ width: "100%", height: "1200px" }}
                 src={url}
                ></iframe>
            </CardContent>
            {url}

        </Card>
    );
}
